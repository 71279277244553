/*
Template Name: Veltrix - Admin & Dashboard Template
Author: Themesbrand
Version: 4.2.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/page-title";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components//waves";
@import "custom/components/avatar";
@import "custom/components/alert";
@import "custom/components/helper";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";


// Plugins
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/parsley";
@import "custom/plugins/color-picker";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/datepicker";
@import "custom/plugins/datatable";
@import "custom/plugins/table-editable";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/responsive-table";
@import "custom/plugins/x-editable";
@import "custom/plugins/morris";
@import "custom/plugins/chartist";
@import "custom/plugins/apexcharts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";

// Pages
@import "custom/pages/authentication";
@import "custom/pages/email";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";
@import "custom/pages/gallery";

// PrimeReact
//theme
@import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
@import "primereact/resources/primereact.min.css";

//icons
@import "primeicons/primeicons.css"; 