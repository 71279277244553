.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #70ad47 !important;
  background-color: #70ad00 !important;
  border: 1px double #70ad00 !important;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #98D42C !important;
  background-color: #98D42C !important;
  color: #fff !important;
  border: 1px double #B4E261 !important;
}

.CalendarDay__selected_span {
  background: #B4E261 !important;
  border: 1px double #98D42C !important;
  color: #fff !important;
}