@import "./icons.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

body {
  * {
    outline: none;
    font-family: 'Varela Round';
  }
}
#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

.fa,
.fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
[class*=" ti-"],
[class^="ti-"] {
  font-family: themify;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.noti-icon .badge {
  left: 23px;
}
.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  margin: 2em 0;
}
// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.ReactModal__Overlay {
  z-index: 1001 !important;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}
.external-event {
  &:hover {
    cursor: pointer;
  }
}
a:hover {
  cursor: pointer;
}
.rating-container {
  background-color: transparent !important;
}
.input-group-append {
  z-index: 0;
}
.input-color {
  color: $input-color !important;
}
.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}
.flatpickr-weekdays {
  background: $primary !important;
}
span.flatpickr-weekday {
  background: $primary !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}
span.flatpickr-weekday {
  color: $white !important;
}
.flatpickr-day.selected {
  background: $primary !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Crypto enteries
.bs-select select {
  width: auto !important;
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  // .control-dots {
  //   margin-top: 35px !important;
  // }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}
.carousel {
  .control-dots {
    margin: -5px 0px;
  }
  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

// calendar modal
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg-dark) center /
    $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}

.wizard .actions {
  position: relative !important;
  display: block !important;
  text-align: right !important;
  width: 100% !important;
}
.wizard .actions > ul {
  display: inline-block !important;
  text-align: right !important;
  padding-left: 0 !important;
}
.wizard .actions > ul > li {
  display: inline-block !important;
  margin: 0 0.5em !important;
}

//Dropdown
.dropup .dropdown-menu {
  top: auto !important;
  bottom: 100% !important;
  transform: translate3d(5px, 5px, 0px) !important;
}

//form editor
.rdw-editor-toolbar,
.rdw-editor-main {
  border: 1px solid #ccc !important;
}

//Calendar
.app-calendar .btn {
  text-transform: capitalize;
}
.dropzone:focus {
  outline: none !important ;
}
//dropdown
.dropdown-menu.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}
.card-columns {
  column-count: 3;
  grid-column-gap: 1.25rem;
  -webkit-column-gap: 1.25rem;
  grid-column-gap: 24px;
  column-gap: 24px;
  orphans: 1;
  widows: 1;
}
.ion-ios-checkmark-circle-outline {
  font-size: 3.5rem;
  line-height: 56px;
}
label {
  margin-bottom: 0.5rem;
}
.DraftEditor-editorContainer {
  margin-left: 14px !important;
}

.wizard .steps .nav-link {
  background-color: #c6eebb;
  color: #70ad47;
}

.wizard .steps .nav-link .number {
  border-color: #70ad47;
}

.apexcharts-canvas {
  text {
    fill: $text-muted !important;
  }
}

// RightSidebar

.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}
.radio-toolbar label {
  display: inline-block;
  background-color: $gray-300;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;
}
.radio-toolbar input[type="radio"]:checked + label {
  background-color: $primary;
  border: none;
  color: $white;
}

#radio-title {
  margin-bottom: 5px;
}

//
// Button Sizes
//

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem !important;
  font-size: 1.09375rem !important;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.76563rem !important;
}

.faq.accordion-button {
  &:after {
    content: "\F0377";
    display: block;
    font-family: "Material Design Icons";
    font-size: 20px;
    color: $text-muted;
    position: absolute;
    right: 20px;
    font-weight: $font-weight-medium;
    top: 50%;
    background-image: none;
    transform: translateY(-50%);
  }

  &.collapsed::after {
    content: "\F0419";
  }
}

.rdw-editor-toolbar,
.rdw-option-wrapper,
.rdw-editor-main {
  border-color: $input-border-color !important;
  background-color: var(--#{$prefix}custom-white) !important;
}

.rdw-option-wrapper {
  border: none !important;
  box-shadow: none !important;
}

.rdw-dropdown-wrapper {
  background-color: $input-bg !important;
  border-color: $input-border-color !important;
  box-shadow: none !important;
}

.rdw-dropdown-selectedtext {
  color: var(--#{$prefix}body-color) !important;
}
// select
.select2-selection__control {
  background-color: $input-bg !important;
  border-color: $input-border-color !important;
}
.react-select-2-live-region{
  background-color: $input-bg !important;
  border-color: $input-border-color !important;
}
.select2-selection__placeholder,
.css-14el2xx-placeholder {
  color: $input-placeholder-color !important;
}

.select2-selection__input-container,
.select2-selection__single-value {
  color: $input-color !important;
}

.select2-selection__menu-list,
.select2-selection__menu {
  background-color: var(--#{$prefix}custom-white) !important;
  border-radius: 4px !important;
}

.select2-selection__menu {
  box-shadow: none !important;
  border: 1px solid $input-border-color !important;
}

.select2-selection__indicator-separator {
  background-color: $input-border-color !important;
}

.select2-selection__option--is-focused,
.select2-selection__option--is-selected {
  background-color: $white !important;
  color: $primary !important;

}
.select2-selection__option--is-focused,
.select2-selection__option--is-selected:hover {
  background-color: $uco-green-focus !important;
  color: $primary !important;
}
.select2-selection__group-heading {
  color: $text-muted !important;
}

.form-control__control,
.css-1s2u09g-control {
  background-color: $input-bg !important;
  border-color: $input-border-color !important;
}

.form-control__value-container,
.css-319lph-ValueContainer,
.css-g1d714-ValueContainer {
  color: $input-color !important;
}

.css-26l3qy-menu {
  background-color: var(--#{$prefix}custom-white) !important;
  border-radius: 4px !important;
}
.css-1nmdiq5-menu {
  background-color: $white !important;
  border-radius: 4px !important;
}
.css-1n7v3ny-option{
  background-color: $primary !important;
  color: $white !important;
}

.select2-selection__menu-list, .css-4ljt47-MenuList{
  background-color: $input-bg !important;
}

.pi:not(.p-toast-message-icon) {
  font-size: $font-size-base !important;
}

.p-inputtext:not(.box-filter) {
  padding: 0.4rem 0.75rem !important;
}

.p-inputtext.user-filter {
  padding: 0.4rem 1.75rem !important;
}

.p-component, .p-link, .p-inputtext {
  font-family: 'Varela Round' !important;
  font-size: $font-size-base !important;
}

.p-highlight {
  color: #70ad47 !important;
  background-color: #eafbf6 !important;
}

.p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:focus {
  outline: none !important;
}

.p-link:focus {
  box-shadow: none !important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  min-width: 2.5rem !important;
  height: 2.5rem !important;
}

.blur-marker { 
  opacity: 50% !important;
}

.highlight-marker, .selected-marker {
  margin-left: -24.5px !important;
  margin-top: -54px !important;
  width: 49px !important;
  height: 54px !important;
  opacity: 100% !important;
}

.highlight-marker-svg,
.selected-marker-svg {
  margin-left: -12.5px !important;
    margin-top: -27px !important;
    width: 49px !important;
    height: 54px !important;
    opacity: 100% !important;
}

.p-inputtext:enabled {
  &:hover {
    border-color: #70ad47 !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem #eafbf6 !important;
    border-color: #70ad47 !important;
  }
}

.p-dropdown { 
  height: 35px !important;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem #eafbf6 !important;
  border-color: #70ad47 !important;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #70ad47 !important;
}

.p-datatable .p-datatable-header {
  padding: 0.5rem !important;
}

.field-location, .field-address {
  max-width: 8rem !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.box-information, .add-modal-body {
  p, ul {
    margin: 0 !important
  }
}

.p-toast-message-content {
  padding: 0.7rem !important;
}

.btn-refresh {
  padding: 0 !important;
  border: 0 !important;
  i {
    color: #495057 !important;
  }
}

.payment-status-dropdown {
  background-color: $primary !important;
  span.p-inputtext, span.p-dropdown-trigger-icon {
    color: $white !important;
  }
}

.export-icon button {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.apexcharts-canvas text.trade-range {
  fill: white !important;
}

.uco-price {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.trans-detail {
  ul {
    padding-left: 1rem !important;
  }
}

tr.p-datatable-row-expansion {
  background-color: #eafbf6 !important;
}

.p-dropdown-panel{
  z-index: 1200 !important;
}

.box-images-container {
  max-height: 210px;
  width: 100%;
  overflow: auto;
  .image-container {
    position: relative;
    height: 210px;
    width: 200px;
    .box-image {
      margin-top: 10px;
      object-fit: cover;
      border-radius: 20px;
      border: 1px solid gray;
    }
  }
}

img.box-delete-icon {
  cursor: pointer;
  position: absolute;
  /* Position the icon absolutely within its parent */
  top: 0;
  /* Align the icon to the top edge of the container */
  right: -7px;
  /* Align the icon to the right edge of the container */
  margin: 0px;
  /* Add some margin for spacing */
}

.add-image-button {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.add-modal-body {
  max-height: 700px;
  overflow: auto;
}

.CalendarDay__selected_span {
  background: $primary; //background
  color: white; //text
  border: 1px solid $primary; //default styles include a border
}

.p-inputswitch.p-inputswitch-checked {
  .p-inputswitch-slider {
    background: $primary !important;
  }
}

.p-inputswitch.p-focus {
  .p-inputswitch-slider {
    box-shadow: 0 0 0 0.2rem $primary-focus !important;
  }
}

input:disabled {
  background-color: #f0f0f0 !important; /* Change the background color to gray */
  color: #888 !important; /* Change the text color to a lighter gray */
}

.hover-list-item:hover {
  background-color: #c8ecb7; /* Change to the desired background color on hover */
  cursor: pointer; /* Change the cursor to a pointer on hover (optional) */
}

.list-group-container {
  max-height: 150px; /* Set the maximum height as per your requirements */
  overflow-y: auto; /* Add vertical scrollbar when the content exceeds the maximum height */
}

.modal-header.with-close {
  .modal-title {
    width: 100%;
  }
  .btn-close {
    margin: 0 !important;
  }
}

.min-modal-body {
  min-height: 480px !important;
}


